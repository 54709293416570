import { fetchGet, fetchPost } from '../index';

//? 按groupCode查询数据字典
export const findByGroupCode = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/claimDictionary/findByGroupCode`, { params });
};

//? 获取全部年款
export const getModelYear = (params: any): Promise<any> => {
    return fetchGet(`/vapi/noAuth/get_property_lov`, { params });
};

//? 7782 获取提交截止日期列表(每个经销商是不同的)
export const confirmDeadlines = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/dealer/communication/find/confirmDeadlines`, {
        params,
    });
};

//? 7782 经销商查看三合一
export const communicationData = (params: any): Promise<any> => {
    return fetchGet(
        `/claimapi/dealer/communication/find/sipoCommunicated/page`,
        {
            params,
        }
    );
};

//? 7784 弹出上传支持文档页面
export const editSupport = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/appeal/file/edit/supportDoc`, {
        params,
    });
};

//? 7784 支持文档预览
export const supportView = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/appeal/file/support/review`, {
        params,
    });
};

//? 7784 文档上传
export const docUpload = (params: any): Promise<any> => {
    return fetchPost(`/claimapi/appeal/file/doc/upload`, params);
};

//? 7784 支持文档覆盖上传
export const supportUpload = (params: any): Promise<any> => {
    return fetchPost(`/claimapi/appeal/file/support/upload`, params);
};

//? 7785  获取申诉类型下拉框
export const getAppealTypes = (): Promise<any> => {
    return fetchGet(`/claimapi/appeal/file/getAppealTypes`);
};

//? 7785 弹出上传申诉文档页面
export const editAppealDoc = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/appeal/file/edit/appealDoc`, {
        params,
    });
};

//? 7785 申诉文件预览
export const appealView = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/appeal/file/appeal/review`, {
        params,
    });
};

//? 7785 申诉文档覆盖上传
export const appealUpload = (params: any): Promise<any> => {
    return fetchPost(`/claimapi/appeal/file/appeal/upload`, params);
};

//? 7787 经销商查看历史
export const communicationHistoryData = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/dealer/communication/find/history/page`, {
        params,
    });
};

//? 接受
export const communicationApprove = (params: any): Promise<any> => {
    return fetchPost(`/claimapi/dealer/communication/approved`, params);
};

//? 不接受
export const communicationReject = (params: any): Promise<any> => {
    return fetchPost(`/claimapi/dealer/communication/reject`, params);
};

//? 7783 获取详情信息
export const dealerClaimDetail = (id: string): Promise<any> => {
    return fetchGet(`/claimapi/dealer/claim/detail/info/${id}`);
};

//? 7783 发票预览
export const invoiceView = (params: any): Promise<any> => {
    return fetchGet(`/rvapi/basic/certificate/invoiceView`, { params });
};

//? 7783 行驶证预览
export const licenseView = (params: any): Promise<any> => {
    return fetchGet(`/rvapi/basic/certificate/licenseView`, { params });
};

//! 预览
//显示已经上传的图片 2278
export const docReview = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/claimDocCollection/review', { params });
};
//显示单个上传图片 2276实际是4954再用
export const claimReview = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealerClaimSupportingDoc/review', { params });
};

// export const getBasicDetail = (params = {}): Promise<any> => {
//     return fetchGet('/rvapi/basic/finance/retail/retailInfo', params)
// }
